import { Card } from 'antd';
import Billing from './billing';
import './style.scss';
import Content from '../../../settingsInnerContent/content';

const PaymentManagement = () => {
  return (
    <div className="w-100 d-flex">
      <div className="w-30 px-4">
        <Content
          heading="Credit Card Info"
          content="Manage your billing credit card. "
        />
      </div>
      <div className="w-70 ">
        <div className="payment-management">
          <Card title="">
            <h2>Select Card</h2>
            <h2>{/* $165.23 <span>balance</span> */}</h2>
            <Billing />
          </Card>
        </div>
      </div>
    </div>
  );
};
export default PaymentManagement;
