import { Card } from 'antd';
import Content from '../../settingsInnerContent/content';
import Flex from '../../../../sharedComponents/flex';
import Loading from '../../../../sharedComponents/loading';
import { currency } from '../../../../utils/function';

const NextBilling = (props) => {
  const { loading, list } = props;

  return (
    <div className="w-100 d-flex">
      <div className="w-30 px-4">
        <Content
          heading="Next Billing Info"
          content="See a preview of the what the next billing charge will be. Note that this number will change if your customer membership count changes before the next billing cycle."
        />
      </div>
      <div className="w-70">
        <div className="settings-invoices-table">
          <Card>
            <Flex
              alignItems="center"
              justifyContent="between"
              mobileFlex={false}
            >
              <Flex className="mb-1" mobileFlex={false}>
                <div className="mr-md-3 mb-3">
                  <h2>Next Billing Info</h2>
                </div>
              </Flex>
            </Flex>
            {loading ? (
              <Loading cover="content" />
            ) : (
              <>
                <div className="d-flex justify-content-between w-50 ">
                  <p className="font-weight-bold">Amount</p>
                  <p className="font-weight-bold">{currency(list)}</p>
                  {/* <Table columns={tableColumns} dataSource={list} rowKey="1" /> */}
                </div>
              </>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
export default NextBilling;
