import { useState, useEffect } from 'react';
import { v1 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { MotionDiv } from '@clinkee/winebar';
import { Row, Col, Button, Card, Alert, Modal, message } from 'antd';
import { editCollections } from '../../../utils/function';

import Questions from '../../questions';

const { confirm } = Modal;
export const EditQuestion = (props) => {
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    questions: [],
  });
  useEffect(() => {
    if (selectedClub) {
      const dubData = { ...data };
      dubData.questions = selectedClub.questions ? selectedClub.questions : [];
      setData(dubData);
    }
  }, [selectedClub]);
  const onEditClubSuccess = () => {
    props.history.push('/questionnaire');
    message.success('Questions saved');
  };
  const changesHandler = () => {
    setLoader(true);
    confirm({
      title: 'Do you Want to save changes?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        const dubClub = { ...selectedClub };
        dubClub.questions = data.questions;
        editCollections('clubs', dubClub, onEditClubSuccess);
      },
      onCancel() {
        setLoader(false);
      },
    });
  };
  const discardChanges = () => {
    setLoading(true);
    confirm({
      title: 'Are you sure  you Want to  cancel changes?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        props.history.push('/questionnaire');
      },
      onCancel() {
        setLoading(false);
      },
    });
  };
  return (
    <Row gutter={16} className="Questionnaire layout-setting ">
      <Col
        xs={24}
        sm={24}
        md={13}
        lg={24}
        xlg={24}
        className="setting-layout-container p-4"
      >
        <div className=" d-flex justify-content-between">
          <h2 className="mb-3">Questions</h2>
          <div className="mb-3">
            <Button
              onClick={() => {
                discardChanges();
              }}
              className="mr-2"
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              loading={loader}
              type="primary"
              onClick={() => {
                changesHandler();
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <Card title="" className="w-70">
          {data && data.questions.length > 0 && (
            <MotionDiv showMessage={error}>
              <Alert type="error" showIcon message={error} />
            </MotionDiv>
          )}
          <Questions setError={setError} data={data} setData={setData} />
        </Card>
        <div className="d-flex justify-content-center my-2 w-70">
          <Button
            className="w-100"
            type="dashed"
            onClick={() => {
              const dubData = { ...data };
              const question = {
                id: uuid(),
                answers: [],
                question: '',
              };
              if (dubData.questions.length === 0) {
                dubData.questions.push(question);
                setData({ ...dubData });
              } else if (dubData.questions.find((sin) => !sin.question)) {
                setError('Question is required.');
              } else if (
                dubData.questions.find((sin) => sin.answers.length === 0)
              ) {
                setError('Add atleast one answer');
              } else {
                dubData.questions = Object.assign([], dubData.questions);
                dubData.questions.push(question);
                setData(dubData);
              }
            }}
          >
            <PlusOutlined />
            Add Question
          </Button>
        </div>
      </Col>
    </Row>
  );
};
export default withRouter(EditQuestion);
