import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_DARK,
  NAV_TYPE_SIDE,
} from '../../../constants/ThemeConstant';
import MenuContent from './settingsMenuContent';
import './style.scss';
// import Settinginnercontent from '../settingsInnerContent';

const { Sider } = Layout;

export const Sett = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
  accountStatusHandler,
  sideNavToggle,
}) => {
  const props = {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    accountStatusHandler,
  };
  return (
    <div className="setting-sidenav-conatiner d-flex">
      <div className="w-30">
        <Sider
          className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? '' : ''}`}
          width={SIDE_NAV_WIDTH}
          collapsed={navCollapsed}
        >
          <Scrollbars autoHide>
            <MenuContent
              type={NAV_TYPE_SIDE}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              accountStatusHandler={accountStatusHandler}
              sideNavToggle={sideNavToggle}
            />
          </Scrollbars>
        </Sider>
      </div>
    </div>
  );
};

// const mapStateToProps = ({ theme }) => {
//   const { navCollapsed, sideNavTheme } =  theme;
//   return { navCollapsed, sideNavTheme }
// };

export default connect()(Sett);
