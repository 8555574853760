import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export default firebase.initializeApp({
  apiKey: 'AIzaSyD-OOuNnV4_vFlus-fhLcsK-dY2LeH7BKg',
  authDomain: 'clinkee-app.firebaseapp.com',
  databaseURL: 'https://clinkee-app.firebaseio.com',
  projectId: 'clinkee-app',
  storageBucket: 'clinkee-app.appspot.com',
  messagingSenderId: '1054357625127',
  appId: '1:1054357625127:web:76ccc5539c3761e793f102',
  measurementId: 'G-NPB3DVW1M3',
});
