import { useEffect, useState } from 'react';
import { Col, Card, Button, Switch, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Flex from '../../../../sharedComponents/flex';
import Content from '../../settingsInnerContent/content';
import { editCollections } from '../../../../utils/function';
import { setSelectedClub } from '../../../../store/actions/clubActions';
import '../../style.scss';

const { confirm } = Modal;
const AccountStatus = (props) => {
  const { history } = props;
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [status, setStatus] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedClub) {
      setStatus(
        selectedClub && selectedClub.status ? selectedClub.status : 'active'
      );
    }
  }, []);

  const onSuccess = () => {
    message.success(`Club status successfully changed`);
  };

  const onCancelSuccess = () => {
    message.success(`Club status successfully changed`);
    dispatch(setSelectedClub(null));
    history.push('/');
  };

  const onError = () => {
    message.error('Something went wrong please try again');
  };

  const statusHandler = () => {
    if (status === 'active') {
      setStatus('pause');
      const dublicateData = { ...selectedClub };
      dublicateData.status = 'pause';
      editCollections('clubs', dublicateData, onSuccess, onError);
    } else {
      setStatus('active');
      const dublicateData = { ...selectedClub };
      dublicateData.status = 'active';
      editCollections('clubs', dublicateData, onSuccess, onError);
    }
  };
  const accountStatusHandler = () => {
    confirm({
      title: 'Are you sure you want to pause your account status?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        statusHandler();
      },
      onCancel() {},
    });
  };

  const accountCancelHandler = () => {
    confirm({
      title: 'Are you sure you want to cancel your account?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        setStatus('cancel');
        const dublicateData = { ...selectedClub };
        dublicateData.status = 'cancel';
        editCollections('clubs', dublicateData, onCancelSuccess, onError);
      },
      onCancel() {},
    });
  };
  return (
    <div className="w-100 d-flex">
      <div className="w-30 px-4">
        <Content
          heading=" Account Status"
          content="Change the current status of your account.

          Pausing your subscription will save your data for when you decide to reactivate your account.

Canceling account your account will deleted your data. You'll have access until the end of the current billing period. "
        />
      </div>
      <div className="w-70 ">
        <div className="settings-club-info">
          <Col xs={24} sm={24} md={13} lg={24} xlg={24}>
            <Card>
              <Flex
                className="py-2 font-weight-bold"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Pause Account</h2>
                <div className="mb-3 d-flex justify-content-start align-items-center">
                  <Switch
                    size="large"
                    checked={status === 'pause'}
                    checkedChildren="on"
                    unCheckedChildren="off"
                    onChange={accountStatusHandler}
                  />
                </div>
              </Flex>
              <Flex
                className="py-2 font-weight-bold"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Cancel Account</h2>
                <div className="mb-3">
                  <Button
                    className="gradient-background"
                    onClick={accountCancelHandler}
                  >
                    Cancel Account
                  </Button>
                </div>
              </Flex>
            </Card>
          </Col>
        </div>
      </div>
    </div>
  );
};
export default withRouter(AccountStatus);
