import { useState, useEffect } from 'react';
import { Modal, Table, Button, Input, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { editCollections } from '../../utils/function';
import Loading from '../../sharedComponents/loading';
import AvatarStatus from '../../sharedComponents/avatarStatus';
import Flex from '../../sharedComponents/flex';
import utils from '../utils';
import './style.scss';

export const Model = (props) => {
  const {
    visible,
    setVisible,
    shipmentProductsBackup,
    setShipmentProductsBackup,
    shipment,
  } = props;
  //   const [form] = Form.useForm();
  //   const [error] = useState('');
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const firestore = useSelector((state) => state.firestore);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const productsCollection = `products?where=clubId,==,${selectedClub.id}`;
  useFirestoreConnect([
    {
      collection: 'products',
      where: [['clubId', '==', selectedClub.id]],
    },
  ]);
  useEffect(() => {
    if (
      firestore.status.requested[productsCollection] &&
      !firestore.status.requesting[productsCollection]
    ) {
      setList([...firestore.ordered.products]);
      setShipmentProductsBackup([...firestore.ordered.products]);
      setSelectedProducts(shipment.products);
      setLoading(false);
    }
  }, [firestore.status, shipment]);
  const updateUserOrders = () => {
    message.success(`${selectedProducts.length} products selected.`);
    setShipmentProductsBackup(selectedProducts);
    const dubShipment = { ...shipment };
    dubShipment.products = selectedProducts;
    editCollections('shipments', dubShipment);
    setVisible(false);
  };
  const revertsUserOrders = () => {
    setVisible(false);
  };
  const addProduct = (elm) => {
    const dubProducts = [...selectedProducts];
    elm.quantity = elm.quantity ? elm.quantity : 0;
    elm.questions = selectedClub.questions;
    dubProducts.push(elm);
    setSelectedProducts(dubProducts);
  };
  const removeProduct = (id) => {
    const dubProducts = [...selectedProducts];
    const filter = dubProducts.filter((product) => product.id !== id);
    setSelectedProducts(filter);
  };
  const tableColumns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'productName'),
      render: (productName, elm) => (
        <AvatarStatus
          size={60}
          type="square"
          src={elm.productImage ? elm.productImage : ''}
          name={productName}
        />
      ),
    },
    {
      title: 'Selected',
      dataIndex: 'selected',
      key: 'quantity',
      render: (_, elm) => (
        <div>
          {selectedProducts.length > 0 &&
          selectedProducts.find((product) => product.id === elm.id) ? (
            <Button
              className="bg-red w-100"
              onClick={() => {
                removeProduct(elm.id);
              }}
            >
              Remove
            </Button>
          ) : (
            <Button
              className="bg-cyan w-100"
              onClick={() => {
                addProduct(elm);
              }}
            >
              Add
            </Button>
          )}
        </div>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'Menu',
    //   key: 'action',
    //     render: (_, elm, ind) => (
    //       <div className="text-right">
    //         <EllipsisDropdown menu={dropdownMenu(elm, ind)} />
    //       </div>
    //     ),
    // },
  ];
  const onSearch = (e) => {
    const { value } = e.currentTarget;
    let dupShipment = [...list];
    const dupShipmentProducts = [...shipmentProductsBackup];
    dupShipment = utils.wildCardSearch(dupShipmentProducts, value);
    setList(dupShipment);
  };
  return (
    <>
      <Modal
        className="product-list-model"
        title="Select Items"
        visible={visible}
        okText="Ok"
        cancelButtonProps={{ style: { display: loading ? 'none' : '' } }}
        okButtonProps={{
          style: {
            display: loading ? 'none' : '',
          },
        }}
        closeIcon={
          <h3 className="item-list-header">
            {selectedProducts.length} item selected
          </h3>
        }
        onOk={() => {
          updateUserOrders();
        }}
        onCancel={() => {
          revertsUserOrders();
        }}
      >
        {loading ? (
          <div className=" d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          <>
            <Flex
              className="mb-1 w-100"
              mobileFlex={false}
              justifyContent="between"
            >
              <div className="mr-md-3 mb-3">
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  onChange={(e) => onSearch(e)}
                />
              </div>
            </Flex>
            <Table
              columns={tableColumns}
              dataSource={list}
              rowKey="id"
              pagination={false}
            />
          </>
        )}
      </Modal>
    </>
  );
};
export default withRouter(Model);
