import React from 'react';
import { useSelector, connect } from 'react-redux';

import { Grid } from 'antd';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from '../../constants/ThemeConstant';
// import { APP_NAME } from 'configs/AppConfig';
import utils from '../utils';
import LogoImg from '../../assets/images/Logo-PNG-01.png';
import './style.scss';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return 'auto';
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  }
  return `${SIDE_NAV_WIDTH}px`;
};

// const getLogo = (props) => {
//   const { navCollapsed, logoType } = props;
//   if(logoType === 'light') {
//     if(navCollapsed) {
//       return '../../assets/images/Logo-PNG-01.png'
//     }
//     return '/img/logo-white.png'
//   }

//   if (navCollapsed) {
//     return '../../assets/images/Logo-PNG-01.png'
//   }
//   return '/img/logo.png'
// }

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }
  return 'logo';
};

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const selectedClub = useSelector((state) => state.club.selectedClub);

  return (
    <div
      // eslint-disable-next-line react/destructuring-assignment
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <img
        src={selectedClub.clubLogo ? selectedClub.clubLogo : LogoImg}
        alt={`${'clinkee'} logo`}
        className="logo-height"
      />
    </div>
  );
};

// const mapStateToProps = ({ theme }) => {
//   const { navCollapsed, navType } =  theme;
//   return { navCollapsed, navType }
// };

export default connect()(Logo);
