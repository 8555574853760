/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { NAV_TYPE_SIDE } from '../../constants/ThemeConstant';
import MenuContent from './menuContent';
// import { onMobileNavToggle } from "redux/actions/Theme";
import { Logo } from './logo';
import Flex from '../../sharedComponents/flex';

export const MobileNav = ({
  sideNavTheme,
  mobileNav,
  // onMobileNavToggle,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };

  const onClose = () => {
    // onMobileNavToggle(false);
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="h-100">
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

// const mapStateToProps = ({ theme }) => {
//   const { navCollapsed, sideNavTheme, mobileNav } = theme;
//   return { navCollapsed, sideNavTheme, mobileNav };
// };

export default connect()(MobileNav);
