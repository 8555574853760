/* eslint-disable no-sequences */
import { useState, useEffect } from 'react';
import { Card, Table, Input, Row, Button, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  // DeleteOutlined,
  // ExclamationCircleOutlined,
} from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import EllipsisDropdown from '../../../sharedComponents/ellipsisDropdown';
import { currency } from '../../../utils/function';
import Content from '../settingsInnerContent/content';
import Flex from '../../../sharedComponents/flex';
import utils from '../../utils';
import Loading from '../../../sharedComponents/loading';

// const { confirm } = Modal;
const CheckoutListView = (props) => {
  const { history } = props;
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [list, setList] = useState();
  let status = true;
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (selectedClub && selectedClub.checkout) {
      setList([...selectedClub.checkout]);
      setLoader(false);
    }
  }, [selectedClub]);
  const viewDetails = (row) => {
    history.push(`/settings/edit-checkout/${row.id}`);
  };

  // const onSuccess = () => {
  //   message.success('Successfully deleted');
  // };
  // const onError = () => {
  //   message.error('Something went wrong');
  // };
  // const deleteInstance = (row) => {
  //   const dublicateClub = { ...selectedClub };
  //   dublicateClub.checkout = dublicateClub.checkout.filter(
  //     (sin) => sin.id !== row.id
  //   );
  //   editCollections('clubs', dublicateClub, onSuccess, onError);
  // };
  // const deleteRow = (row) => {
  //   confirm({
  //     title: 'Do you Want to delete these items?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: '',
  //     onOk() {
  //       deleteInstance(row);
  //     },
  //     onCancel() {},
  //   });
  // };

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item onClick={() => viewDetails(row)}>
        <Flex alignItems="center">
          <EyeOutlined />
          <span className="ml-2">View Details</span>
        </Flex>
      </Menu.Item>
      {/* <Menu.Item onClick={() => deleteRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">Delete item</span>
        </Flex>
      </Menu.Item> */}
    </Menu>
  );

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'title',
      render: (title) => <div className="cursor-pointer">{title}</div>,
    },

    {
      title: 'Price',
      dataIndex: 'price',
      render: (price) => (
        <div className="cursor-pointer">
          <NumberFormat
            displayType="text"
            value={currency(price)}
            prefix="$"
            thousandSeparator
          />
        </div>
      ),
      sorter: (a, b) => (a, b, 'price'),
    },
    {
      title: 'Items',
      dataIndex: 'itemCount',
      render: (itemCount) => (
        <div className="cursor-pointer">
          <NumberFormat displayType="text" value={itemCount} />
        </div>
      ),
      sorter: (a, b) => (a, b, 'amount'),
    },
    {
      title: '',
      dataIndex: 'Menu',
      render: (_, elm) => (
        <div
          className="text-right"
          onClick={() => {
            status = false;
          }}
        >
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];
  //   const rowSelection = {
  //     onChange: (key, rows) => {
  //       setSelectedRows(rows);
  //       setSelectedRowKeys(key);
  //     },
  //   };
  const onSearch = (e) => {
    const { value } = e.currentTarget;
    if (value) {
      const data = utils.wildCardSearch(list ? [...list] : [], value);
      setList(data);
    } else {
      setList([...selectedClub.checkout]);
    }
    // setSelectedRowKeys([]);
  };
  return (
    <div className="w-100">
      <div className="w-100 ">
        <div className="settings-checkout">
          <Row xs={24} sm={24} md={13} lg={24} xlg={24}>
            <div className="w-30 px-4">
              <Content
                heading="Club Plans"
                content="Manage the different plans that your customers can subscribe. "
              />
            </div>
            <Card title="" className="w-70">
              <Flex
                alignItems="center"
                justifyContent="between"
                mobileFlex={false}
              >
                <h2>Plan</h2>
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="between"
                mobileFlex={false}
              >
                <Flex
                  className="mb-1 w-100"
                  mobileFlex={false}
                  justifyContent="between"
                >
                  <div className="mr-md-3 mb-3">
                    <Input
                      placeholder="Search"
                      prefix={<SearchOutlined />}
                      onChange={(e) => onSearch(e)}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        history.push('/settings/add-checkout-fields');
                      }}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                      block
                    >
                      Add
                    </Button>
                  </div>
                </Flex>
              </Flex>
              {loader ? (
                <Loading cover="content" />
              ) : (
                <div className="table-responsive">
                  <Table
                    columns={tableColumns}
                    dataSource={list}
                    rowKey="id"
                    onRow={(row) => ({
                      onClick: () => {
                        if (status) {
                          setTimeout(() => {
                            history.push(`/settings/edit-checkout/${row.id}`);
                          }, 200);
                        }
                        status = true;
                      },
                    })}
                  />
                </div>
              )}
            </Card>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default withRouter(CheckoutListView);
