import React from 'react';
// import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
// import { LogoutOutlined } from '@ant-design/icons';
// import firebase from 'firebase';
// import IntlMessage from "../utilComponents/IntlMessage";
import { connect } from 'react-redux';
import Icon from '../../utilComponents/icon';
import navigationConfig from './settingsNavigationConfig';
import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
} from '../../../constants/ThemeConstant';
import utils from '../../utils';
// import { setSelectedClub } from '../../../store/actions/clubActions';
// import { onMobileNavToggle } from "redux/actions/Theme";
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;
// const setLocale = (isLocaleOn, localeKey) =>
//   isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const setLocale = (isLocaleOn, localeKey) => localeKey.toString();
const setDefaultOpen = (key) => {
  const keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index += 1) {
      const elm = arr[index];
      // eslint-disable-next-line no-unused-expressions
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};
const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    sideNavToggle,
  } = props;
  // const dispatch = useDispatch();
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      sideNavToggle(false);
    }
  };
  // const logOut = () => {
  //   dispatch(setSelectedClub(null));
  //   firebase
  //     .auth()
  //     .signOut()
  //     .then(function () {
  //       // Sign-out successful.
  //     })
  //     .catch(function (error) {
  //       // An error happened.
  //     });
  // };
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : ''}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            // title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) =>
                    subMenuSecond.submenu.length > 0 ? (
                      <SubMenu
                        icon={
                          subMenuSecond.icon ? (
                            <Icon type={subMenuSecond?.icon} />
                          ) : null
                        }
                        key={subMenuSecond.key}
                        title={setLocale(localization, subMenuSecond.title)}
                      >
                        {subMenuSecond.submenu.map((subMenuThird) =>
                          subMenuThird.submenu.length > 0 ? (
                            <SubMenu
                              icon={
                                subMenuThird.icon ? (
                                  <Icon type={subMenuThird?.icon} />
                                ) : null
                              }
                              key={subMenuThird.key}
                              title={setLocale(
                                localization,
                                subMenuThird.title
                              )}
                            >
                              {subMenuThird.submenu.map((subMenuFourth) => (
                                <Menu.Item key={subMenuFourth.key}>
                                  {subMenuFourth.icon ? (
                                    <Icon type={subMenuFourth?.icon} />
                                  ) : null}
                                  <span>
                                    {setLocale(
                                      localization,
                                      subMenuFourth.title
                                    )}
                                  </span>
                                  <Link
                                    onClick={() => closeMobileNav()}
                                    to={subMenuFourth.path}
                                  />
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          ) : (
                            <Menu.Item key={subMenuThird.key}>
                              {subMenuThird.icon ? (
                                <Icon type={subMenuThird?.icon} />
                              ) : null}
                              <span>
                                {setLocale(localization, subMenuThird.title)}
                              </span>
                              <Link
                                onClick={closeMobileNav}
                                to={subMenuThird.path}
                              />
                            </Menu.Item>
                          )
                        )}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};
const TopNavContent = (props) => {
  const { topNavColor } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {/* {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )} */}
    </Menu>
  );
};
const MenuContent = (props) => {
  const { type } = props;
  return type === NAV_TYPE_SIDE ? (
    // eslint-disable-next-line
    <SideNavContent {...props} />
  ) : (
    // eslint-disable-next-line
      <TopNavContent {...props} />
  );
};
// const mapStateToProps = ({theme}) => {
//   const {sideNavTheme, topNavColor} = theme;
//   return {sideNavTheme, topNavColor};
// };
export default connect()(MenuContent);
