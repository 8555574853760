import { connect } from 'react-redux';
import { Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Logo } from './logo';
import { NAV_TYPE_TOP } from '../../constants/ThemeConstant';

const { Header } = Layout;
export const HeaderNav = (props) => {
  const {
    navType,
    headerNavColor,
    showSideNav,
    setShowSideNav,
    isMobile,
  } = props;

  // const onToggle = () => {
  //   if(!isMobile) {
  //     toggleCollapsedNav(!navCollapsed)
  //   } else {
  //     onMobileNavToggle(!mobileNav)
  //   }
  // }

  const isNavTop = navType === NAV_TYPE_TOP;
  // const mode = utils.getColorContrast(headerNavColor)
  const mode = '';
  // const getNavWidth = () => {
  //   if (isNavTop || isMobile) {
  //     return '0px'
  //   }
  //   // if(navCollapsed) {
  //   //   return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  //   // } else {
  //   //   return `${SIDE_NAV_WIDTH}px`
  //   // }
  // }
  return (
    <Header
      className={`app-header ${mode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode} />
        {isMobile && showSideNav && (
          <MenuFoldOutlined
            fill="#eb2f96"
            className="nav-icon cursor-pointer p-3"
            onClick={() => {
              setShowSideNav(false);
            }}
          />
        )}
        {isMobile && !showSideNav && (
          <MenuUnfoldOutlined
            id="mobileMenuOpener"
            fill="#eb2f96"
            className="nav-icon cursor-pointer p-3"
            onClick={() => {
              setShowSideNav(true);
            }}
          />
        )}
        <div
          className="nav"
          style={{
            width: `calc(100% )`,
            alignItems: 'center',
          }}
        >
          {/* <div className="nav-left">
               
              {/* {
                isMobile ?
                  <Menu.Item key="1" onClick={() => { onSearchActive() }}>
                    <SearchOutlined />
                  </Menu.Item>
                  :
                  <Menu.Item key="1" style={{ cursor: 'auto' }}>
                    <SearchInput mode={mode} isMobile={isMobile} />
                  </Menu.Item>
              } */}
          <div className="nav-right">
            {/* <NavNotification />
            <NavLanguage />
            <NavProfile />
            <NavPanel /> */}
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
        </div>
      </div>
    </Header>
  );
};

// const mapStateToProps = ({ theme }) => {
//   const { navCollapsed, navType, headerNavColor, mobileNav } =  theme;
//   return { navCollapsed, navType, headerNavColor, mobileNav }
// };

export default connect()(HeaderNav);
