import React from 'react';
import LoaderGif from '../../assets/images/loader.gif';
import './style.scss';

const Loader = () => (
  <div className="mainWrapper">
    <img className="loader" src={LoaderGif} alt="loader" />
    <h2>Please wait ...</h2>
  </div>
);
export default Loader;
