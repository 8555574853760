import { useState } from 'react';
import { Form, Modal, Select, message, Alert } from 'antd';
// import firebase from 'firebase';
import { MotionDiv } from '@clinkee/winebar';
import { withRouter, NavLink } from 'react-router-dom';
import { cloudFunction } from '../../utils/function';
import Loading from '../../sharedComponents/loading';

export const Model = (props) => {
  const {
    visible,
    setVisible,
    title,
    selectedClub,
    selectedPlan,
    setSelectedPlan,
    history,
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const planHandler = async () => {
    if (!selectedPlan) {
      setVisible(false);
    } else {
      setLoading(true);
      const payLoad = {
        planId: selectedPlan.id,
        clubId: selectedClub.id,
      };
      cloudFunction('createShipment', payLoad)
        .then((res) => {
          setLoading(false);
          message.success(`Shipment Created`);
          history.push(`/edit-shipment/${res.data.data}`);
        })
        .catch((err) => {
          setLoading(false);
          setError(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message
          );
        });
    }
  };
  const cancel = () => {
    setVisible(false);
  };
  const SelectPlanHandler = (value) => {
    setError(null);
    const plan = selectedClub.checkout.find((each) => each.id === value);
    setSelectedPlan(plan || null);
  };
  return (
    <>
      <Modal
        title={title}
        visible={visible}
        okText="Confirm"
        cancelButtonProps={{
          style: {
            display: loading ? 'none' : '',
          },
        }}
        okButtonProps={{
          style: {
            display: loading || !selectedPlan ? 'none' : '',
          },
        }}
        onOk={() => {
          planHandler();
        }}
        onCancel={() => {
          cancel();
        }}
      >
        {selectedClub.checkout && selectedClub.checkout.length > 0 ? (
          <Form form={form} name="addCardForm" layout="vertical">
            {error && (
              <MotionDiv showMessage={!!error}>
                <Alert type="error" showIcon message={error} />
              </MotionDiv>
            )}
            {loading ? (
              <div className=" d-flex justify-content-center">
                <Loading />
              </div>
            ) : (
              <Form.Item name="selectedPlan" label="Select Plan">
                <Select
                  showSearch
                  value={(selectedPlan && selectedPlan.id) || ''}
                  placeholder="Select a Plan"
                  onChange={SelectPlanHandler}
                >
                  {selectedClub.checkout &&
                    selectedClub.checkout.map((sin) => {
                      return (
                        <Select.Option key={sin.id} value={sin.id}>
                          {sin.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}
          </Form>
        ) : (
          <p className="f-s-14">
            There are currently no plans created for this club.
            <NavLink to="/settings/checkout">
              {' '}
              Visit the Club Plans section in the Settings{' '}
            </NavLink>
            menu to add your first plan.
          </p>
        )}
      </Modal>
    </>
  );
};
export default withRouter(Model);
