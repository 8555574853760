import { useState, useEffect } from 'react';
import { Collapse, Form, Select } from 'antd';
import { NavLink } from 'react-router-dom';
import '../style.scss';
import Loading from '../../../sharedComponents/loading';
import { currency } from '../../../utils/function';

const { Panel } = Collapse;
// eslint-disable-next-line no-unused-vars
function callback(key) {}

const PlanCard = (props) => {
  const {
    selectedClub,
    setSelectedPlan: setUserSelectedPlan,
    selectedPlan: userSelectedPlan,
    setLastChargeDate,
    setChargeDate,
  } = props;
  const [selectedPlan, setSelectedPlan] = useState();
  const [loading] = useState(false);
  const { Option } = Select;
  const date = new Date();
  useEffect(() => {
    const plan = selectedClub.checkout.find(
      (each) => each.id === userSelectedPlan
    );
    setSelectedPlan(plan);
  }, [userSelectedPlan]);
  const SelectPlanHandler = (value) => {
    const plan = selectedClub.checkout.find((each) => each.id === value);
    if (plan) {
      setSelectedPlan(plan);
      setUserSelectedPlan(plan && plan.id);
      setChargeDate(null);
      setLastChargeDate(date.toISOString());
    } else {
      setSelectedPlan(null);
      setUserSelectedPlan('');
      setChargeDate('N/A');
      setLastChargeDate('N/A');
    }
  };
  return selectedClub &&
    selectedClub.checkout &&
    selectedClub.checkout.length > 0 ? (
    <div className="plan-card">
      {loading ? (
        <Loading cover="content" />
      ) : (
        <Collapse
          defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition="right"
        >
          <Panel
            header={
              <Form.Item className="m-0">
                <Select
                  className="w-90"
                  value={selectedPlan && selectedPlan.id}
                  onChange={SelectPlanHandler}
                  placeholder="Select Plan"
                >
                  {selectedClub.checkout &&
                    selectedClub.checkout.map((sin) => {
                      return (
                        <Option key={sin.id} value={sin.id}>
                          {sin.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            }
            key="1"
          >
            {selectedPlan && (
              <>
                <div className="plan-inner-div">
                  <div className="w-50">Cost:</div>
                  <div className="w-50">
                    {selectedPlan && currency(selectedPlan.price)}
                  </div>
                </div>
                <div className="plan-inner-div">
                  <div className="w-50">Frequency:</div>
                  <div className="w-50">
                    {selectedPlan && selectedPlan.billingFrequency}
                  </div>
                </div>
                <div className="plan-inner-div">
                  <div className="w-50">Item Count:</div>
                  <div className="w-50">
                    {selectedPlan && selectedPlan.itemCount}
                  </div>
                </div>
                <div className="plan-inner-div">
                  <div className="w-50">Shipping Cost:</div>
                  <div className="w-50">
                    {selectedPlan && selectedPlan.shippingCost
                      ? currency(selectedPlan.shippingCost)
                      : currency(0)}
                  </div>
                </div>
              </>
            )}
          </Panel>
        </Collapse>
      )}
    </div>
  ) : (
    <p className="f-s-14">
      There are currently no plans created for this club.
      <NavLink to="/settings/checkout">
        {' '}
        Visit the Club Plans section in the Settings{' '}
      </NavLink>
      menu to add your first plan.
    </p>
  );
};
export default PlanCard;
