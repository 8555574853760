import { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { editCollections } from '../../utils/function';
import Loading from '../../sharedComponents/loading';
import AvatarStatus from '../../sharedComponents/avatarStatus';
// import utils from '../utils';
import './style.scss';
import Preferences from './preferenceSection';

export const Model = (props) => {
  const { visible, setVisible, productDetail, shipment } = props;
  const [loading] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    const dubShipment = { ...shipment };
    const dubData = { ...data };
    dubData.questions = Object.assign(
      [],
      dubShipment.products.find((sin) => sin.id === productDetail.id).questions
    );
    setData(dubData);
  }, [setData, productDetail]);
  const onSuccess = () => {
    message.success('Preferences successfully selected');
  };
  const preferencesSelectionHandler = () => {
    const dubShipment = { ...shipment };
    dubShipment.products.find((sin) => sin.id === productDetail.id).questions =
      data.questions;
    editCollections('shipments', dubShipment, onSuccess);
    setVisible(false);
  };
  return (
    <>
      <Modal
        className="prefrence-selection-model"
        title="Preference Selection"
        visible={visible}
        okText="Save"
        cancelButtonProps={{ style: { display: loading ? 'none' : '' } }}
        okButtonProps={{ style: { display: loading ? 'none' : '' } }}
        onOk={() => {
          preferencesSelectionHandler();
        }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        {loading ? (
          <Loading cover="content" />
        ) : (
          <>
            <div className="pref-product-image d-flex">
              <AvatarStatus
                size={80}
                type="square"
                src={productDetail.productImage}
                name={productDetail.productName}
                subTitle={productDetail.productName}
              />
            </div>
            <p className="pt-4">
              Where does this product land on your customer prefrence questions?
              Answering these helps us auto-generate preferred selections for
              your customers.
            </p>
            <Preferences data={data} setData={setData} />
          </>
        )}
      </Modal>
    </>
  );
};
export default withRouter(Model);
