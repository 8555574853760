import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form } from 'antd';
import { Button } from '@clinkee/winebar';
import { cloudFunction, getParams } from '../../utils/function';
import Loading from '../../sharedComponents/loading';
import './style.scss';

const StripeConfirmation = (props) => {
  const { history } = props;
  let code =
    history.location && history.location.search
      ? getParams(history.location.search)
      : '';
  code = code && code.code ? code.code : '';
  const [loading, setLoading] = useState(true);
  const selectedClub = useSelector((state) => state.club.selectedClub);

  const handleOk = () => {
    cloudFunction('connectAccount', {
      code,
      clubId: selectedClub.id,
    })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (code) {
      handleOk();
    }
  }, [code]);

  const FormItem = Form.Item;
  return loading ? (
    <Loading cover="content" />
  ) : (
    <div className="login-page vh-100 bg-white">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                <h1 className="font-weight-bold">
                  Successfully Connected to Stripe
                </h1>
                <div className="mt-4">
                  <FormItem>
                    <Button
                      className="gradient-background"
                      onClick={() => {
                        history.push('/');
                      }}
                      buttonText="Ok"
                    />
                  </FormItem>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(StripeConfirmation);
