import { Card, Table, Tag } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useFirestoreConnect } from 'react-redux-firebase';
import NumberFormat from 'react-number-format';
import Content from '../../settingsInnerContent/content';
import Flex from '../../../../sharedComponents/flex';
import Loading from '../../../../sharedComponents/loading';
import { currency } from '../../../../utils/function';

const Invoices = () => {
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const firestore = useSelector((state) => state.firestore);
  const [list, setList] = useState();
  const [loader, setLoader] = useState(true);
  useFirestoreConnect([
    {
      collection: 'clubTransaction',
      where: [['clubId', '==', selectedClub.id]],
    },
  ]);
  useEffect(() => {
    if (
      firestore.status.requested[
        `clubTransaction?where=clubId,==,${selectedClub.id}`
      ] &&
      !firestore.status.requesting[
        `clubTransaction?where=clubId,==,${selectedClub.id}`
      ]
    ) {
      setList([...firestore.ordered.clubTransaction]);
      setLoader(false);
    }
  }, [firestore, firestore.status]);
  const statusColorHandler = (status) => {
    if (status === 'charge_succeeded' || status === 'charge_manually') {
      return 'cyan';
    }
    if (status === 'refund_succeeded') {
      return 'gold';
    }
    return 'red';
  };

  const tableColumns = [
    // {
    //   title: 'Invoices Number',
    //   dataIndex: 'id',
    //   render: (id) => <div>{id}</div>,
    //   // sorter: (a, b) => (a, b, 'invoiceNumber'),
    // },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount) => (
        <div>
          <NumberFormat
            displayType="text"
            value={currency(amount)}
            prefix="$"
            thousandSeparator
          />
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <Tag
          className="text-capitalize pt-0"
          color={statusColorHandler(status)}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'chargeDate',
      render: (chargeDate) => <div>{moment(chargeDate).format('L')}</div>,
      // sorter: (a, b) => (a, b, 'date'),
    },
  ];
  //   const rowSelection = {
  //     onChange: (key, rows) => {
  //       setSelectedRows(rows);
  //       setSelectedRowKeys(key);
  //     },
  //   };
  // const onSearch = (e) => {
  //   const { value } = e.currentTarget;
  //   const data = utils.wildCardSearch(
  //     ProductListData ? [...ProductListData] : [],
  //     value
  //   );
  //   setList(data);
  //   // setSelectedRowKeys([]);
  // };
  return (
    <div className="w-100 d-flex">
      <div className="w-30 px-4">
        <Content
          heading="Invoices"
          content="Review the invoices you've received from us."
        />
      </div>
      <div className="w-70">
        <div className="settings-invoices-table">
          <Card>
            <Flex className="mb-1" mobileFlex={false}>
              <h2>Invoices</h2>
              {/* <div className="mr-md-3 mb-3">
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  onChange={(e) => onSearch(e)}
                />
              </div> */}
            </Flex>
            <div className="table-responsive">
              {loader ? (
                <Loading cover="content" />
              ) : (
                <Table columns={tableColumns} dataSource={list} rowKey="1" />
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Invoices;
