import { Row, Col, Button, Card, Progress } from 'antd';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageHeader from '../layoutComponents/pageHeader';
import Loading from '../../sharedComponents/loading';
import './style.scss';

export const Questionnaire = (props) => {
  const { history } = props;
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [questions, setQuestions] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setQuestions(selectedClub.questions ? selectedClub.questions : []);
    setLoader(false);
  }, [selectedClub]);

  return (
    <>
      <PageHeader title="Questionnaire" />
      <Row gutter={16} className="Questionnaire layout-setting ">
        <Col
          xs={24}
          sm={24}
          md={13}
          lg={24}
          xlg={24}
          className="setting-layout-container p-4"
        >
          <div className=" d-flex justify-content-between">
            <h2 className="mb-3">Questions</h2>
            <div
              className="mb-3"
              onClick={() => {
                history.push('/edit-questions');
              }}
            >
              <Button type="primary">Edit Questions</Button>
            </div>
          </div>
          {loader ? (
            <Loading cover="content" />
          ) : (
            <>
              {questions.map((sin) => (
                <Card
                  className="Question-section"
                  title={sin.question}
                  key={sin.id}
                >
                  {sin.answers.map((options) => (
                    <Row key={options.id}>
                      <Col xs={8} sm={8} md={8} lg={8} xlg={8}>
                        <Progress percent={options.totalCount || 0} />
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={8} xlg={8}>
                        <p>{options.answer}</p>
                      </Col>
                    </Row>
                  ))}
                </Card>
              ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default withRouter(Questionnaire);
