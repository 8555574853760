import { useEffect, useState } from 'react';
import { Card, Table, Input, Button, Menu, Tag, Modal } from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import EllipsisDropdown from '../../sharedComponents/ellipsisDropdown';
import Flex from '../../sharedComponents/flex';
import InputViewForm from '../../sharedComponents/viewFormat';
import utils from '../utils';
import { deleteCollections, editCollections } from '../../utils/function';
import Loading from '../../sharedComponents/loading';

const { confirm } = Modal;

const UserManagementList = () => {
  const history = useHistory();
  const firestore = useSelector((state) => state.firestore);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [UserListData, setUserListData] = useState([]);
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  let status = true;
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const usersCollection = `users?where=clubId,==,${selectedClub.id}`;
  useFirestoreConnect([
    {
      collection: 'users',
      where: [['clubId', '==', selectedClub.id]],
    },
  ]);

  useEffect(() => {
    if (
      firestore.status.requested[usersCollection] &&
      !firestore.status.requesting[usersCollection]
    ) {
      setUserListData([...firestore.ordered.users]);
      setList([...firestore.ordered.users]);
      setLoader(false);
    }
  }, [firestore.status]);

  const viewDetails = (row) => {
    history.push(`/edit-user/${row.id}`);
  };

  const addUser = () => {
    history.push(`/add-user`);
  };
  const RowClick = (rowId, path) => {
    if (status) {
      setTimeout(() => {
        history.push(`${path}${rowId}`);
      }, 200);
    }
    status = true;
  };
  const deleteInstance = (row) => {
    const objKey = 'id';
    let data = list;
    const club = { ...selectedClub };
    if (selectedRows.length > 1) {
      selectedRows.forEach((elm) => {
        data = utils.deleteArrayRow(data, objKey, elm.id);
        club.userCount -= 1;
        editCollections('clubs', club);
        deleteCollections('users', elm.id);
        setList(data);
        setSelectedRows([]);
      });
    } else {
      club.userCount -= 1;
      editCollections('clubs', club);
      data = utils.deleteArrayRow(data, objKey, row.id);
      deleteCollections('users', row.id);
      setList(data);
    }
  };

  const deleteRow = (row) => {
    confirm({
      title: 'Do you Want to delete these items?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteInstance(row);
      },
      onCancel() {},
    });
  };

  const userPaidHandler = (elm) => {
    if (elm.plan) {
      const plan = selectedClub.checkout.find((each) => each.id === elm.plan);
      if (
        elm.balance >=
        parseFloat(plan.price) + parseFloat(plan.shippingCost)
      ) {
        return 'Paid';
      }
      return 'Unpaid';
    }
    return 'N/A';
  };

  const userPaidClassHandler = (elm) => {
    if (elm.plan) {
      const plan = selectedClub.checkout.find((each) => each.id === elm.plan);
      if (
        elm.balance >=
        parseFloat(plan.price) + parseFloat(plan.shippingCost)
      ) {
        return 'cyan';
      }
      return 'red';
    }
    return 'red';
  };
  const dropdownMenu = (row) => {
    status = true;
    return (
      <Menu>
        <Menu.Item onClick={() => viewDetails(row)}>
          <Flex alignItems="center">
            <EyeOutlined />
            <span className="ml-2">View Details</span>
          </Flex>
        </Menu.Item>
        <Menu.Item onClick={() => deleteRow(row)}>
          <Flex alignItems="center">
            <DeleteOutlined />
            <span className="ml-2">
              {selectedRows.length > 0
                ? `Delete (${selectedRows.length})`
                : 'Delete'}
            </span>
          </Flex>
        </Menu.Item>
      </Menu>
    );
  };

  const tableColumns = [
    {
      title: 'Name',
      key: 'firstName',
      render: (row) => <div className="cursor-pointer">{row.firstName}</div>,
      sorter: (a, b) => utils.antdTableSorter(a, b, 'firstName'),
    },
    {
      title: 'Email',
      key: 'userEmail',
      render: (row) => <div className="cursor-pointer">{row.userEmail}</div>,
      sorter: (a, b) => utils.antdTableSorter(a, b, '/userEmail/'),
    },
    {
      title: 'Product',
      key: 'product',
      render: (_, elm) => (
        <div className="cursor-pointer">
          {elm.plan ? (
            selectedClub.checkout.find((each) => each.id === elm.plan).title
          ) : (
            <InputViewForm value="" />
          )}
        </div>
      ),
    },
    {
      title: 'Paid/Unpaid ',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (_, elm) => (
        <Tag
          className="text-capitalize pt-0 cursor-pointer"
          color={userPaidClassHandler(elm)}
        >
          {userPaidHandler(elm)}
        </Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'Menu',
      key: 'Menu',
      render: (_, elm) => (
        <div
          className="text-right"
          onClick={() => {
            status = false;
          }}
        >
          <EllipsisDropdown className="transform-90" menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  // const rowSelection = {
  //   onChange: (key, rows) => {
  //     setSelectedRows(rows);
  //     setSelectedRowKeys(key);
  //   },
  // };

  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const searchArray = [...UserListData];
    const data = utils.wildCardSearch(searchArray, value);
    setList(data);
    // setSelectedRowKeys([]);
  };
  return !loader ? (
    <Card>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e)}
            />
          </div>
        </Flex>
        <div>
          <Button
            onClick={addUser}
            type="primary"
            icon={<PlusCircleOutlined />}
            block
          >
            Add User
          </Button>
        </div>
      </Flex>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          rowKey="id"
          onRow={(row) => ({
            onClick: () => {
              RowClick(row.id, '/edit-user/');
            },
          })}
        />
      </div>
    </Card>
  ) : (
    <Loading cover="content" />
  );
};

export default UserManagementList;
