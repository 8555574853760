import { Collapse } from 'antd';
import { useState, useEffect } from 'react';
import './style.scss';

const PrefrenceSelection = (props) => {
  const { data, setData } = props;
  const [active, setActive] = useState(null);
  const { Panel } = Collapse;
  const callback = (key) => {
    if (key) {
      setActive(key);
    } else {
      setActive(null);
    }
  };

  useEffect(() => {
    if (data && data.questions && data.questions.length && !active) {
      let newOpen = data.questions.find((each) => !each.selectedAnswerId);
      newOpen = newOpen ? newOpen.id : null;
      setActive(newOpen);
    }
  }, [data]);

  return (
    <>
      <div className="prefrences-selection">
        {data &&
          data.questions &&
          data.questions.length &&
          data.questions.map((single, index) => (
            <div key={single.id}>
              <Collapse
                accordion
                bordered={false}
                key={single.id}
                defaultActiveKey={[active]}
                activeKey={active}
                onChange={callback}
                expandIcon={() => {
                  return (
                    <div className="circle-container">
                      <div
                        className={
                          single && single.selectedAnswer
                            ? 'circle-dot'
                            : 'grey-dot'
                        }
                      />
                    </div>
                  );
                }}
              >
                <>
                  <Panel
                    header={
                      <div
                        className={
                          single && single.selectedAnswer ? '' : 'gray-text'
                        }
                      >
                        {single.question}
                      </div>
                    }
                    key={single.id}
                  >
                    {single &&
                      single.answers &&
                      single.answers.length !== 0 &&
                      single.answers.map((item) => (
                        <div
                          key={item.id}
                          className="single-answer-item"
                          onClick={() => {
                            const duplicateData = { ...data };
                            duplicateData.questions[index].selectedAnswerId =
                              item.id;
                            duplicateData.questions[index].selectedAnswer =
                              item.answer;
                            let newOpen = duplicateData.questions.find(
                              (each) => !each.selectedAnswerId
                            );
                            newOpen = newOpen ? newOpen.id : null;
                            setActive(newOpen);
                            // duplicateData.questions[
                            //   index
                            // ].answers = duplicateData.questions[
                            //   index
                            // ].answers.map((each) => {
                            //   const singleAns = { ...each };
                            //   if (singleAns.id === item.id) {
                            //     singleAns.totalCount = +1;
                            //     return singleAns;
                            //   }
                            //   singleAns.totalCount = 0;
                            //   return singleAns;
                            // });
                            setData({ ...duplicateData });
                          }}
                        >
                          <p
                            className={
                              item.id === single.selectedAnswerId
                                ? 'active-ant-input'
                                : 'ant-unactive-input'
                            }
                          >
                            {item.answer ? item.answer : ''}
                          </p>
                        </div>
                      ))}
                  </Panel>
                  {active !== single.id && single && single.selectedAnswer ? (
                    <div className="selected-answer-container">
                      <div className="selected-single-answer">
                        {single.selectedAnswer}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              </Collapse>
            </div>
          ))}
      </div>
    </>
  );
};

export default PrefrenceSelection;
