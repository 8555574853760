import BillingInfoForm from './billingInfoForm';
import SelectCreditCard from './selectCreditCard';

const BillingInfo = () => {
  return (
    <div className="d-flex flex-direction-column Billing-Info w-100">
      <SelectCreditCard />
      <BillingInfoForm mode="EDIT" />
    </div>
  );
};

export default BillingInfo;
