import { Card, Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { connectedStripeRedirectedUrl } from '../../../config/config';
import Content from '../settingsInnerContent/content';

const StripePaymentMethod = () => {
  const selectedClub = useSelector((state) => state.club.selectedClub);

  return (
    <>
      <div className="w-100">
        <div className="w-100 ">
          <div className="settings-payment-provider">
            <Row xs={24} sm={24} md={13} lg={24} xlg={24}>
              <div className="w-30 px-4">
                <Content
                  heading="Payment Provider Info"
                  content="Integrate a payment provider to automatically charge your customers for their monthly memberships to your club. "
                />
              </div>
              <div className="w-70 px-4">
                <Card className="checkout-form-container">
                  <h2>Stripe Payment Integration</h2>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-100"
                    disabled={!!selectedClub.connectedStripeUserId}
                    onClick={() => {
                      window.location.href = connectedStripeRedirectedUrl;
                    }}
                  >
                    {selectedClub.connectedStripeUserId
                      ? 'Connected With Stripe'
                      : 'Connect Stripe'}
                  </Button>
                </Card>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default StripePaymentMethod;
