import { useState, useEffect } from 'react';
import { Input, InputNumber, Col, Card, Form, Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { editCollections } from '../../../../utils/function';
import Flex from '../../../../sharedComponents/flex';
import InputViewForm from '../../../../sharedComponents/viewFormat';
import Content from '../../settingsInnerContent/content';

const BillingInfoForm = (props) => {
  const { mode, param } = props;
  const [formMode, setFormMode] = useState('VIEW');
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (mode === 'EDIT') {
      if (selectedClub) {
        form.setFieldsValue({
          id: selectedClub.id,
          email:
            selectedClub.billingAddress && selectedClub.billingAddress.email
              ? selectedClub.billingAddress.email
              : '',
          companyName:
            selectedClub.billingAddress &&
            selectedClub.billingAddress.companyName
              ? selectedClub.billingAddress.companyName
              : '',
          address1:
            selectedClub.billingAddress && selectedClub.billingAddress.address1
              ? selectedClub.billingAddress.address1
              : '',
          address2:
            selectedClub.billingAddress && selectedClub.billingAddress.address2
              ? selectedClub.billingAddress.address2
              : '',
          city:
            selectedClub.billingAddress && selectedClub.billingAddress.city
              ? selectedClub.billingAddress.city
              : '',
          state:
            selectedClub.billingAddress && selectedClub.billingAddress.state
              ? selectedClub.billingAddress.state
              : '',
          zipCode:
            selectedClub.billingAddress && selectedClub.billingAddress.zipCode
              ? selectedClub.billingAddress.zipCode
              : '',
        });
      }
    }
  }, [form, mode, param, props, formMode]);

  const rules = {
    companyName: [
      {
        required: true,
        message: 'Please enter Company Name',
      },
    ],
    email: [
      {
        required: true,
        message: 'Please input your email',
      },
      {
        type: 'email',
        message: 'Please enter a valid email',
      },
    ],
    state: [
      {
        required: true,
        message: 'Please enter State',
      },
    ],
    city: [
      {
        required: true,
        message: 'Please enter City',
      },
    ],
    address1: [
      {
        required: true,
        message: 'Please enter Address 1',
      },
    ],
    code: [
      {
        required: true,
        message: 'Please enter a Zip Code',
      },
      {
        type: 'number',
        message: 'Please enter a valid Zip code',
      },
    ],
  };

  const onSuccess = () => {
    setSubmitLoading(false);
    setFormMode('VIEW');
    message.success(`Billing Info saved`);
  };

  const onError = () => {
    setSubmitLoading(false);
    message.error('Something went wrong please try again');
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      setSubmitLoading(true);
      const dublicateClub = { ...selectedClub };
      dublicateClub.billingAddress = dublicateClub.billingAddress
        ? dublicateClub.billingAddress
        : {};
      dublicateClub.billingAddress.email = values.email ? values.email : '';
      dublicateClub.billingAddress.companyName = values.companyName
        ? values.companyName
        : '';
      dublicateClub.billingAddress.address1 = values.address1
        ? values.address1
        : '';
      dublicateClub.billingAddress.address2 = values.address2
        ? values.address2
        : '';
      dublicateClub.billingAddress.city = values.city ? values.city : '';
      dublicateClub.billingAddress.state = values.state ? values.state : '';
      dublicateClub.billingAddress.zipCode = values.zipCode
        ? values.zipCode
        : '';
      if (mode === 'ADD') {
        // addCollections('clubs', values, onSuccess, onError);
      } else if (mode === 'EDIT') {
        editCollections('clubs', dublicateClub, onSuccess, onError);
      }
    });
    // .catch(() => {
    //   setSubmitLoading(false);
    //   message.error('Please enter all required field ');
    // });
  };

  return (
    <div className="w-100 d-flex">
      <div className="w-30 px-4">
        <Content
          heading="Billing Info"
          content="Manage your billing address. "
        />
      </div>
      <div className="w-70">
        <div className="settings-club-info">
          <Form
            layout="vertical"
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <Col xs={24} sm={24} md={13} lg={24} xlg={24}>
              <Card>
                <Flex
                  className="py-2 font-weight-bold"
                  mobileFlex={false}
                  justifyContent="between"
                  alignItems="center"
                >
                  <h2 className="mb-3">
                    {formMode === 'ADD'
                      ? 'Add Billing Information'
                      : `Edit Billing Information`}{' '}
                  </h2>
                  <div className="mb-3">
                    {formMode === 'EDIT' && (
                      <Button
                        className="mr-2"
                        onClick={() => {
                          setFormMode('VIEW');
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      type="primary"
                      loading={submitLoading}
                      onClick={() => {
                        if (formMode === 'EDIT') {
                          onFinish();
                        } else {
                          setFormMode('EDIT');
                        }
                      }}
                    >
                      {formMode === 'EDIT' ? 'Save' : `Edit`}
                    </Button>
                  </div>
                </Flex>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={formMode === 'EDIT' && rules.email}
                  validateTrigger="onBlur"
                >
                  {formMode === 'EDIT' ? (
                    <Input placeholder="Email" />
                  ) : (
                    <InputViewForm
                      value={selectedClub && selectedClub.billingAddress.email}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  name="companyName"
                  label="Company Name"
                  rules={formMode === 'EDIT' && rules.companyName}
                  validateTrigger="onBlur"
                >
                  {formMode === 'EDIT' ? (
                    <Input placeholder="Company Name" />
                  ) : (
                    <InputViewForm
                      value={
                        selectedClub && selectedClub.billingAddress.companyName
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item
                  validateTrigger="onBlur"
                  name="address1"
                  label="Address 1"
                  rules={formMode === 'EDIT' && rules.address1}
                >
                  {formMode === 'EDIT' ? (
                    <Input placeholder="Address 1" />
                  ) : (
                    <InputViewForm
                      value={
                        selectedClub && selectedClub.billingAddress.address1
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item
                  validateTrigger="onBlur"
                  name="address2"
                  label="Address 2"
                  rules={formMode === 'EDIT' && rules.address2}
                >
                  {formMode === 'EDIT' ? (
                    <Input placeholder="Address 2" />
                  ) : (
                    <InputViewForm
                      value={
                        selectedClub && selectedClub.billingAddress.address2
                      }
                    />
                  )}
                </Form.Item>

                <Form.Item
                  validateTrigger="onBlur"
                  name="city"
                  label="City"
                  rules={formMode === 'EDIT' && rules.city}
                >
                  {formMode === 'EDIT' ? (
                    <Input placeholder="City" />
                  ) : (
                    <InputViewForm
                      value={selectedClub && selectedClub.billingAddress.city}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  validateTrigger="onBlur"
                  name="state"
                  label="State"
                  rules={formMode === 'EDIT' && rules.state}
                >
                  {formMode === 'EDIT' ? (
                    <Input placeholder="State" />
                  ) : (
                    <InputViewForm
                      value={selectedClub && selectedClub.billingAddress.state}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  validateTrigger="onBlur"
                  name="zipCode"
                  label="Zip Code"
                  rules={formMode === 'EDIT' && rules.code}
                >
                  {formMode === 'EDIT' ? (
                    <InputNumber placeholder="Zip Code" />
                  ) : (
                    <InputViewForm
                      value={
                        selectedClub && selectedClub.billingAddress.zipCode
                      }
                    />
                  )}
                </Form.Item>
              </Card>{' '}
            </Col>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default BillingInfoForm;
