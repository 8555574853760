import React, { useState } from 'react';
import { Input, Divider, Alert, Form } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import { MotionDiv, Button } from '@clinkee/winebar';
import CustomIcon from '../assets/CustomIcon';
import { GoogleSVG, FacebookSVG } from '../assets/svg/icon';

const FormItem = Form.Item;
const Login = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const showForgetPassword = false;
  const initialCredential = {
    email: '',
    password: '',
  };
  const onGoogleLogin = () => {
    setError('');
    setGoogleLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        setGoogleLoading(false);
        const { additionalUserInfo, user } = res;
        const { profile, isNewUser } = additionalUserInfo;
        if (isNewUser) {
          const { uid } = user;
          const userObj = {
            email: profile.email,
            uid,
            clubs: [],
          };
          firebase
            .firestore()
            .collection('clubAdmin')
            .doc(uid)
            .set(userObj)
            .then(() => {
              setLoading(false);
            })
            .catch(() => {
              setGoogleLoading(false);
            });
        }
      })
      .catch((err) => {
        setGoogleLoading(false);
        setError(err.message);
      });
  };
  const onFacebookLogin = () => {
    setError('');
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((err) => {
        setError(err.message);
      });
  };

  const onLogin = (values) => {
    setError('');
    const { email, password } = values;
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };
  const onForgetPasswordClick = () => {};
  const rules = [
    {
      required: true,
      message: 'Please input your email',
    },
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
  ];

  return (
    <div className="login-form">
      <MotionDiv showMessage={error}>
        <Alert type="error" showIcon message={error} />
      </MotionDiv>
      <Form
        layout="vertical"
        name="login-form"
        initialValues={initialCredential}
        onFinish={onLogin}
      >
        <FormItem
          name="email"
          label="Email"
          rules={rules || ''}
          validateTrigger="onBlur"
        >
          <Input prefix={<MailOutlined className="text-primary" />} />
        </FormItem>
        <FormItem
          className="font-weight-bold"
          name="password"
          validateTrigger="onBlur"
          label={
            <div
              className={`${
                showForgetPassword
                  ? 'd-flex justify-content-between w-100 align-items-center'
                  : ''
              }`}
            >
              <span>Password</span>
              {showForgetPassword && (
                <span
                  onClick={() => onForgetPasswordClick}
                  className="cursor-pointer font-size-sm font-weight-normal text-muted"
                >
                  Forget Password?
                </span>
              )}
            </div>
          }
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
            {
              min: 8,
              message: 'Password length should be 8 characters',
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </FormItem>
        <FormItem>
          <Button
            className="gradient-background"
            htmlType="submit"
            loading={loading}
            buttonText="Sign In"
          />
        </FormItem>
        <div>
          <Divider>
            <span className="text-muted font-size-base font-weight-normal">
              or connect with
            </span>
          </Divider>
          <div className="d-flex justify-content-center">
            <Button
              className="mr-2"
              disabled={loading}
              icon={<CustomIcon svg={GoogleSVG} />}
              loading={googleLoading}
              buttonText="Google"
              onClick={() => onGoogleLogin()}
            />
            <Button
              className="mr-2"
              disabled
              icon={<CustomIcon svg={FacebookSVG} />}
              loading={false}
              buttonText="Facebook"
              onClick={() => onFacebookLogin()}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
export default withRouter(Login);
