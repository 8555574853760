import { useEffect, useState } from 'react';
import { Card, Table, Input, Row, Button } from 'antd';
import { useFirestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  // EyeOutlined,
  // PlusCircleOutlined,
  SearchOutlined,
  //   ExclamationCircleOutlined,
} from '@ant-design/icons';
import Content from '../../../settingsInnerContent/content';
import Loading from '../../../../../sharedComponents/loading';

// import { deleteCollections } from '../../../utils/function';
import Flex from '../../../../../sharedComponents/flex';
// import { useHistory } from 'react-router-dom';
import utils from '../../../../utils';
import AddNewTeamMemeber from '../addNewTeamMember';
// const { confirm } = Modal;
const TeamListView = () => {
  const firestore = useSelector((state) => state.firestore);
  const [AdminListData, setAdminListData] = useState([]);
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showModel, setShowModel] = useState(false);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const adminCollection = `clubAdmin?where=clubs,array-contains,${selectedClub.id}`;
  useFirestoreConnect([
    {
      collection: 'clubAdmin',
      where: [['clubs', 'array-contains', selectedClub.id]],
    },
  ]);

  useEffect(() => {
    if (
      firestore.status.requested[adminCollection] &&
      !firestore.status.requesting[adminCollection]
    ) {
      setAdminListData([...firestore.ordered.clubAdmin]);
      setList([...firestore.ordered.clubAdmin]);
      setLoader(false);
    }
  }, [firestore.status]);
  const tableColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'email'),
    },
  ];
  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const searchArray = [...AdminListData];
    const data = utils.wildCardSearch(searchArray, value);
    setList(data);
  };
  return !loader ? (
    <>
      {showModel && (
        <AddNewTeamMemeber showModel={showModel} setShowModel={setShowModel} />
      )}
      <div className="w-100 ">
        <div className="settings-team-list-view">
          <Row xs={24} sm={24} md={13} lg={24} xlg={24}>
            <div className="w-30 px-4">
              <Content
                heading="Team"
                content="Invite your team members to give them access to Clinkee."
              />
            </div>

            <Card title="" className="w-70">
              <Flex
                className="mb-1 w-100"
                mobileFlex={false}
                justifyContent="between"
              >
                <h2>Current Team</h2>
              </Flex>
              <Flex
                className="mb-1 w-100"
                mobileFlex={false}
                justifyContent="between"
              >
                <div className="mr-md-3 mb-3">
                  <Input
                    placeholder="Search"
                    prefix={<SearchOutlined />}
                    onChange={(e) => onSearch(e)}
                  />
                </div>
                <div className="mb-3">
                  <Button type="primary" onClick={() => setShowModel(true)}>
                    Add
                  </Button>
                </div>
              </Flex>
              <div className="table-responsive">
                <Table columns={tableColumns} dataSource={list} rowKey="id" />
              </div>
            </Card>
          </Row>
        </div>
      </div>
    </>
  ) : (
    <Loading cover="content" />
  );
};
export default withRouter(TeamListView);
