import { Row, Col } from 'antd';
import { Switch, Route } from 'react-router-dom';
import SideNav from './sidenav';
import EditClubInfo from './settingsInnerContent/addClubInfo';
import EditCustomDomain from './settingsInnerContent/addCustomDomain';
import BillingInfo from './accountingAndBilling/billingInfo';
import AccountStatus from './accountingAndBilling/accountStatus';
import TeamListView from './accountingAndBilling/team/listOfTeam';
import CurrentPlan from './accountingAndBilling/currentPlan';
import CheckoutListView from './checkout/checkoutListView';
import AddCheckoutFields from './checkout/add';
import EditCheckoutFields from './checkout/edit';
import PaymentProvider from './stripePaymentManagement';
import './style.scss';

const SettingLayout = (props) => {
  const { settingSideNav, sideNavToggle, isMobile } = props;

  return (
    <>
      <Row gutter={16} className="layout-setting">
        <Col
          xs={24}
          sm={24}
          md={13}
          lg={24}
          xlg={24}
          className="setting-layout-container d-flex"
        >
          {settingSideNav && (
            <div className={isMobile ? 'absolute-position' : ''}>
              <SideNav sideNavToggle={sideNavToggle} isMobile={isMobile} />
            </div>
          )}
          <Switch>
            <Route path="/settings/club-info" exact component={EditClubInfo} />
            <Route
              path="/settings/custom-domain"
              component={EditCustomDomain}
            />
            <Route path="/settings/billing-info" component={BillingInfo} />
            <Route path="/settings/current-plan" component={CurrentPlan} />
            <Route path="/settings/checkout" component={CheckoutListView} />
            <Route
              path="/settings/payment-provider"
              component={PaymentProvider}
            />
            <Route
              path="/settings/add-checkout-fields"
              component={AddCheckoutFields}
            />
            <Route
              path="/settings/edit-checkout/:id"
              component={EditCheckoutFields}
            />
            <Route
              path="/settings/current-team-memebers"
              component={TeamListView}
            />
            <Route path="/settings/account-status" component={AccountStatus} />
          </Switch>
        </Col>
      </Row>
    </>
  );
};

export default SettingLayout;
