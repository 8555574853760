import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import firebase from 'firebase';
import {
  Card,
  Input,
  Menu,
  Tooltip,
  Progress,
  Tag,
  Radio,
  Modal,
  message,
} from 'antd';
import {
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import PageHeader from '../layoutComponents/pageHeader';
import Flex from '../../sharedComponents/flex';
import Loading from '../../sharedComponents/loading';
import EllipsisDropdown from '../../sharedComponents/ellipsisDropdown';
import ItemList from './itemList';
import utils from '../utils';
import { cloudFunction, currency } from '../../utils/function';
import ListModel from './itemListModel';
import ShippingListModel from './shippingItemListModel';
import PreferenceModel from './preferenceSelection';
import UserTable from './userTables';
import './style.scss';

const { confirm } = Modal;
const EDIT = 'EDIT';
const ShipmentForm = (props) => {
  const { param, mode, history } = props;
  const [loading, setLoading] = useState(true);
  const [shippingLoader, setShippingLoader] = useState('');
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [visiblePreferences, setVisiblePreferences] = useState(false);
  const [productDetail, setProductDetail] = useState();
  const [shipment, setShipment] = useState();
  const [shipmentUsersBackup, setShipmentUsersBackup] = useState([]);
  const [shipmentProductsBackup, setShipmentProductsBackup] = useState([]);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [selectedPlan, setSelectedPlan] = useState();
  const [userDetail, setUserDetail] = useState();
  const [shipmentUsers, setShipmentUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  let rowStatus = true;
  useEffect(() => {
    firebase
      .firestore()
      .collection('userShipments')
      .where('shipmentId', '==', param.id)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          const users = [];
          snapshot.forEach((each) => {
            const shipmentData = each.data();
            if (shipmentData) {
              users.push({ ...shipmentData });
            }
          });
          setShipmentUsers([...users]);
          setShipmentUsersBackup([...users]);
          setLoader(false);
        }
      });
  }, []);

  useEffect(() => {
    if (mode === EDIT && param.id) {
      firebase
        .firestore()
        .collection('shipments')
        .where('id', '==', param.id)
        .onSnapshot((snapshot) => {
          if (snapshot.size) {
            snapshot.forEach((each) => {
              const shipmentData = each.data();
              if (shipmentData) {
                setShipment(shipmentData);
                setShipmentProductsBackup(shipmentData.products);
                // setShipmentUsersBackup(shipmentData.users);
                setSelectedPlan(
                  selectedClub.checkout.find(
                    (plan) => plan.id === shipmentData.planId
                  )
                );
                setLoading(false);
              }
            });
          }
        });
    } else {
      setLoading(false);
    }
  }, [mode, param, props]);

  const shippingHandler = (e, elm) => {
    setUserDetail(elm);
    // rowStatus = false;
    if (e.target.value === 'shipped' && elm.shipmentStatus !== 'shipped') {
      setShippingLoader(e.target.value);
      const payLoad = { shipmentId: param.id, userId: elm.id };
      cloudFunction('shipPlanToUser', payLoad)
        .then(() => {
          setShippingLoader('');
          message.success('order shipped successfully');
        })
        .catch((err) => {
          setShippingLoader('');
          message.error(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message
          );
        });
    }
  };
  const deleteInstance = (user) => {
    setLoading(true);
    const payLoad = {
      shipmentId: param.id,
      userId: user.id,
    };
    cloudFunction('deleteUserFromShipment', payLoad)
      .then(() => {
        setLoading(false);
        message.success('user removed from shipment successfully');
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          err && err.response && err.response.data && err.response.data.message
        );
      });
  };
  const lockOrUnlockUserOrders = (elm, lockStatus) => {
    const dubUser = { ...elm };
    dubUser.locked = !lockStatus;
    firebase
      .firestore()
      .collection('userShipments')
      .doc(elm.userShipmentId)
      .update({ ...dubUser })
      .then(() => {})
      .catch(() => {
        message.error('something went wrong');
      });
  };

  const deleteRow = (row) => {
    confirm({
      title: 'Do you Want to delete this user from shipment?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteInstance(row);
      },
      onCancel() {},
    });
  };
  const dropdownMenu = (elm) => (
    <Menu>
      <Menu.Item key="0" onClick={() => history.push(`/edit-user/${elm.id}`)}>
        <Flex alignItems="center" className="py-2">
          <UserOutlined />
          <span className="ml-2">User</span>
        </Flex>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setUserDetail(elm);
          setShow(true);
        }}
      >
        <Flex alignItems="center" className="py-2">
          <UnorderedListOutlined />
          <span className="ml-2">items</span>
        </Flex>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => deleteRow(elm)}>
        <Flex alignItems="center" className="py-2">
          <DeleteOutlined />
          <span className="ml-2">Delete</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );
  const tableColumns = [
    {
      title: 'User',
      dataIndex: 'firstName',
      key: 'user',
      render: (firstName, elm) => (
        <div className="cursor-pointer">
          <div className="font-weight-bold">{`${firstName} ${
            elm.lastName ? elm.lastName : ''
          }`}</div>
          <div>{`${elm.userEmail}`}</div>
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'firstName'),
    },
    {
      title: 'Shipping',
      dataIndex: 'shipmentStatus',
      key: 'shipmentStatus',
      render: (status, elm) => {
        return (
          <div
            onClick={() => {
              rowStatus = false;
            }}
          >
            <Radio.Group
              value={elm.shipmentStatus}
              onChange={(e) => shippingHandler(e, elm)}
            >
              <Radio.Button
                value="unshipped"
                disabled
                className={status === 'unshipped' ? 'unShipmentButton' : ''}
              >
                Unshipped
              </Radio.Button>
              {!elm.paymentStatus ||
              elm.totalShippedItem < elm.totalItemInPlan ? (
                <Tooltip title="This order is either unpaid or doesn't have enough assigned items to be eligible for shipping.">
                  <Radio.Button
                    disabled
                    value="shipped"
                    className={status === 'shipped' ? 'shippedButton' : ''}
                  >
                    Shipped
                  </Radio.Button>
                </Tooltip>
              ) : (
                <Radio.Button
                  value="shipped"
                  disabled={elm.shipmentStatus === 'shipped'}
                  className={status === 'shipped' ? 'shippedButton' : ''}
                >
                  {shippingLoader === 'shipped' && elm.id === userDetail.id ? (
                    <LoadingOutlined />
                  ) : (
                    'Shipped'
                  )}
                </Radio.Button>
              )}
            </Radio.Group>
          </div>
        );
      },
      sorter: (a, b) => utils.antdTableSorter(a, b, 'shipmentStatus'),
    },
    {
      title: 'Payment',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus) => (
        <Tag
          className="text-capitalize pt-0 cursor-pointer"
          color={paymentStatus ? 'cyan' : 'red'}
        >
          {paymentStatus ? 'Paid' : 'Unpaid'}
        </Tag>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'shipmentStatus'),
    },
    {
      title: 'Items',
      key: 'item',
      render: (_, elm) => (
        <div className="cursor-pointer">
          <span>{`${elm.totalShippedItem}/${elm.totalItemInPlan}`}</span>
        </div>
      ),
    },
    {
      title: 'Locked',
      dataIndex: 'locked',
      key: 'locked',
      render: (locked, elm) =>
        elm.shipmentStatus === 'shipped' ? (
          <Tooltip title="You can't unlock an order that has been shipped.">
            <div className="text-center cursor-pointer disable-lock">
              <LockOutlined />
            </div>
          </Tooltip>
        ) : (
          <div
            className="lock"
            onClick={() => {
              rowStatus = false;
            }}
          >
            {!locked ? (
              <UnlockOutlined
                className="text-center cursor-pointer"
                onClick={() => lockOrUnlockUserOrders(elm, locked)}
              />
            ) : (
              <LockOutlined
                className="text-center cursor-pointer"
                onClick={() => lockOrUnlockUserOrders(elm, locked)}
              />
            )}
          </div>
        ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'locked'),
    },
    {
      title: '',
      dataIndex: 'Menu',
      key: 'Menu',
      render: (_, elm) => (
        <div
          className="text-right cursor-pointer"
          onClick={() => {
            rowStatus = false;
          }}
        >
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];
  const onSearch = (e) => {
    const { value } = e.currentTarget;
    let dupShipment = [...shipmentUsers];
    const dupShipmentUsers = [...shipmentUsersBackup];
    dupShipment = utils.wildCardSearch(dupShipmentUsers, value);
    setShipmentUsers(dupShipment);
  };
  const rowClickHandler = (row) => {
    if (rowStatus) {
      setTimeout(() => {
        history.push(`/edit-user/${row.id}`);
      }, 200);
    }
    rowStatus = true;
  };
  return (
    <>
      {!loading ? (
        <>
          {visible && (
            <ListModel
              visible={visible}
              setVisible={setVisible}
              shipmentProductsBackup={shipmentProductsBackup}
              setShipmentProductsBackup={setShipmentProductsBackup}
              shipment={shipment}
            />
          )}
          {show && (
            <ShippingListModel
              visible={show}
              setVisible={setShow}
              shipmentProductsBackup={shipmentProductsBackup}
              shipment={shipment}
              shipmentUsers={shipmentUsers}
              userDetail={userDetail}
            />
          )}
          {visiblePreferences && (
            <PreferenceModel
              visible={visiblePreferences}
              setVisible={setVisiblePreferences}
              productDetail={productDetail}
              shipment={shipment}
            />
          )}
          <div className="edit-shipment">
            <PageHeader title="Edit Shipment" />
            <div className=" w-100 d-flex">
              {/* left side */}
              <div className="w-66">
                <div className="d-flex justify-content w-100">
                  <Card title="Sales" className="w-49 ">
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <h1 className="mb-0 font-weight-bold">
                          {shipment.sales
                            ? currency(shipment.sales)
                            : currency(0)}
                        </h1>
                      </div>
                    </div>
                  </Card>
                  <Card title="Revenue" className="w-49">
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <h1 className="mb-0 font-weight-bold">
                          {shipment.revenue
                            ? currency(shipment.revenue)
                            : currency(0)}
                        </h1>
                      </div>
                    </div>
                  </Card>
                </div>
                {/* user Table */}
                <Card title="Users" className="w-100">
                  <Flex
                    alignItems="center"
                    justifyContent="between"
                    mobileFlex={false}
                  >
                    <Flex
                      className="mb-1 w-100"
                      mobileFlex={false}
                      justifyContent="between"
                    >
                      <div className="mr-md-3 mb-3">
                        <Input
                          placeholder="Search"
                          prefix={<SearchOutlined />}
                          onChange={(e) => onSearch(e)}
                        />
                      </div>
                    </Flex>
                  </Flex>
                  {loader ? (
                    <div className="d-flex justify-content-center">
                      <Loading />
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <UserTable
                        tableColumns={tableColumns}
                        usersData={shipmentUsers}
                        rowClickHandler={rowClickHandler}
                      />
                    </div>
                  )}
                </Card>
              </div>
              {/* right side */}
              <div className="w-33">
                <div className=" mx-2">
                  <Card title="Status" className="h-125">
                    <div className="d-flex">
                      <Tooltip
                        title={`${
                          shipment.usersCount ? shipment.usersCount : 0
                        } Total / ${
                          shipment.paidUserCount ? shipment.paidUserCount : 0
                        } Paid / ${
                          shipment.shippedUserCount
                            ? shipment.shippedUserCount
                            : 0
                        } Shipped`}
                      >
                        <Progress
                          percent={parseInt(
                            (shipment.paidUserCount / shipment.usersCount) *
                              100,
                            10
                          )}
                          success={{
                            percent: parseInt(
                              (shipment.shippedUserCount /
                                shipment.usersCount) *
                                100,
                              10
                            ),
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/* <div>
                    <span>Plan: {selectedPlan.title}</span>
                  </div> */}
                  </Card>

                  <Card title="Plan">
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <h1 className="mb-0 font-weight-bold">
                          {selectedPlan.title}
                        </h1>
                      </div>
                    </div>
                  </Card>
                  <ItemList
                    setVisible={setVisiblePreferences}
                    shipmentId={param && param.id}
                    setVisibleManageProduct={setVisible}
                    shipment={shipment}
                    setShipment={setShipment}
                    shipmentProductsBackup={shipmentProductsBackup}
                    setProductDetail={setProductDetail}
                  />
                </div>
              </div>
              {/* <div className="w-100 d-flex">
                </div> */}
            </div>
          </div>
        </>
      ) : (
        <Loading cover="content" />
      )}
    </>
  );
};
export default withRouter(ShipmentForm);
