import CustomDomainForm from '../customDomain';
import ClubId from '../clubId';

const AddClubInfo = () => {
  return (
    <div className="d-flex flex-direction-column">
      <CustomDomainForm mode="EDIT" />
      <ClubId mode="EDIT" />
    </div>
  );
};

export default AddClubInfo;
