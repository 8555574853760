import { Card, Select } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { calculatePercentage } from '../../utils/function';
import './style.scss';

const StatisticWidget = ({
  title,
  value,
  status,
  subtitle,
  prefix,
  weeklyUserRevenue,
  weeklyUserCount,
  weeklyUserSales,
  setWeeklyUserCount,
  setWeeklyUserRevenue,
  setWeeklyUserSales,
}) => {
  const salesHandler = (val) => {
    if (
      val === 'weekly' &&
      weeklyUserSales.actualValues &&
      weeklyUserSales.actualValues.length > 1
    ) {
      const dubWeeklySales = { ...weeklyUserSales };
      dubWeeklySales.calculatedValue = calculatePercentage(
        weeklyUserSales.actualValues[weeklyUserSales.actualValues.length - 1],
        weeklyUserSales.actualValues[weeklyUserSales.actualValues.length - 2]
      );
      setWeeklyUserSales(dubWeeklySales);
    }
    if (
      val === 'monthly' &&
      weeklyUserSales.actualValues &&
      weeklyUserSales.actualValues.length > 7
    ) {
      const lastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 4,
        weeklyUserSales.actualValues.length
      );
      const secondLastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 8,
        weeklyUserSales.actualValues.length - 4
      );
      const dubWeeklyUserSales = { ...weeklyUserSales };
      dubWeeklyUserSales.calculatedValue = calculatePercentage(
        lastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserSales(dubWeeklyUserSales);
    }
    if (
      val === 'quarterly' &&
      weeklyUserSales.actualValues &&
      weeklyUserSales.actualValues.length > 23
    ) {
      const LastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 12,
        weeklyUserSales.actualValues.length
      );
      const secondLastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 24,
        weeklyUserSales.actualValues.length - 12
      );
      const dubWeeklySales = { ...weeklyUserSales };
      dubWeeklySales.calculatedValue = calculatePercentage(
        LastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserSales(dubWeeklySales);
    }
    if (
      val === 'biannual' &&
      weeklyUserSales.actualValues &&
      weeklyUserSales.actualValues.length > 47
    ) {
      const lastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 24,
        weeklyUserSales.actualValues.length
      );
      const secondLastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 48,
        weeklyUserSales.actualValues.length - 24
      );
      const dubWeeklyUserSales = { ...weeklyUserSales };
      dubWeeklyUserSales.calculatedValue = calculatePercentage(
        lastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserSales(dubWeeklyUserSales);
    }
    if (
      val === 'yearly' &&
      weeklyUserSales.actualValues &&
      weeklyUserSales.actualValues.length > 95
    ) {
      const lastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 48,
        weeklyUserSales.length
      );
      const secondLastNumber = weeklyUserSales.actualValues.slice(
        weeklyUserSales.actualValues.length - 96,
        weeklyUserSales.actualValues.length - 48
      );
      const dubWeeklyUserCount = { ...weeklyUserSales };
      dubWeeklyUserCount.calculatedValue = calculatePercentage(
        lastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserSales(dubWeeklyUserCount);
    }
  };
  const revenueHandler = (val) => {
    if (
      val === 'weekly' &&
      weeklyUserRevenue.actualValues &&
      weeklyUserRevenue.actualValues.length > 1
    ) {
      const dubWeeklyRev = { ...weeklyUserRevenue };
      dubWeeklyRev.calculatedValue = calculatePercentage(
        weeklyUserRevenue.actualValues[
          weeklyUserRevenue.actualValues.length - 1
        ],
        weeklyUserRevenue.actualValues[
          weeklyUserRevenue.actualValues.length - 2
        ]
      );
      setWeeklyUserRevenue(dubWeeklyRev);
    }
    if (
      val === 'monthly' &&
      weeklyUserRevenue.actualValues &&
      weeklyUserRevenue.actualValues.length > 7
    ) {
      const LastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 4,
        weeklyUserRevenue.actualValues.length
      );
      const secondLastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 8,
        weeklyUserRevenue.actualValues.length - 4
      );
      const dubWeeklyUserRev = { ...weeklyUserRevenue };
      dubWeeklyUserRev.calculatedValue = calculatePercentage(
        LastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserRevenue(dubWeeklyUserRev);
    }
    if (
      val === 'quarterly' &&
      weeklyUserRevenue.actualValues &&
      weeklyUserRevenue.actualValues.length > 23
    ) {
      const lastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 12,
        weeklyUserRevenue.actualValues.length
      );
      const secondLastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 24,
        weeklyUserRevenue.actualValues.length - 12
      );
      const dubWeeklyRev = { ...weeklyUserRevenue };
      dubWeeklyRev.calculatedValue = calculatePercentage(
        lastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserRevenue(dubWeeklyRev);
    }
    if (
      val === 'biannual' &&
      weeklyUserRevenue.actualValues &&
      weeklyUserRevenue.actualValues.length > 47
    ) {
      const lastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 24,
        weeklyUserRevenue.actualValues.length
      );
      const secondLastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 48,
        weeklyUserRevenue.actualValues.length - 24
      );
      const dubWeeklyUserRev = { ...weeklyUserRevenue };
      dubWeeklyUserRev.calculatedValue = calculatePercentage(
        lastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserRevenue(dubWeeklyUserRev);
    }
    if (
      val === 'yearly' &&
      weeklyUserRevenue.actualValues &&
      weeklyUserRevenue.actualValues.length > 95
    ) {
      const lastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 48,
        weeklyUserRevenue.length
      );
      const secondLastNumber = weeklyUserRevenue.actualValues.slice(
        weeklyUserRevenue.actualValues.length - 96,
        weeklyUserRevenue.actualValues.length - 48
      );
      const dubWeeklyRev = { ...weeklyUserRevenue };
      dubWeeklyRev.calculatedValue = calculatePercentage(
        lastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0),
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0)
      );
      setWeeklyUserRevenue(dubWeeklyRev);
    }
  };
  const handelChange = (val) => {
    salesHandler(val);
    revenueHandler(val);
    if (
      val === 'weekly' &&
      weeklyUserCount.actualValues &&
      weeklyUserCount.actualValues.length > 1
    ) {
      const dubWeeklyUserCount = { ...weeklyUserCount };
      dubWeeklyUserCount.calculatedValue =
        weeklyUserCount.actualValues[weeklyUserCount.actualValues.length - 1] -
        weeklyUserCount.actualValues[weeklyUserCount.actualValues.length - 2];
      setWeeklyUserCount(dubWeeklyUserCount);
    }
    if (
      val === 'monthly' &&
      weeklyUserCount.actualValues &&
      weeklyUserCount.actualValues.length > 7
    ) {
      const LastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 4,
        weeklyUserCount.actualValues.length
      );
      const secondLastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 8,
        weeklyUserCount.actualValues.length - 4
      );
      const dubWeeklyUserCount = { ...weeklyUserCount };
      dubWeeklyUserCount.calculatedValue =
        LastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0) -
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0);
      setWeeklyUserCount(dubWeeklyUserCount);
    }
    if (
      val === 'quarterly' &&
      weeklyUserCount.actualValues &&
      weeklyUserCount.actualValues.length > 23
    ) {
      const LastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 12,
        weeklyUserCount.actualValues.length
      );
      const secondLastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 24,
        weeklyUserCount.actualValues.length - 12
      );
      const dubWeeklyUserCount = { ...weeklyUserCount };
      dubWeeklyUserCount.calculatedValue =
        LastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0) -
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0);
      setWeeklyUserCount(dubWeeklyUserCount);
    }
    if (
      val === 'biannual' &&
      weeklyUserCount.actualValues &&
      weeklyUserCount.actualValues.length > 47
    ) {
      const lastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 24,
        weeklyUserCount.actualValues.length
      );
      const secondLastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 48,
        weeklyUserCount.actualValues.length - 24
      );
      const dubWeeklyUserCount = { ...weeklyUserCount };
      dubWeeklyUserCount.calculatedValue =
        lastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0) -
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0);
      setWeeklyUserCount(dubWeeklyUserCount);
    }
    if (
      val === 'yearly' &&
      weeklyUserCount.actualValues &&
      weeklyUserCount.actualValues.length > 95
    ) {
      const secondLastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 48,
        weeklyUserCount.length
      );
      const LastNumber = weeklyUserCount.actualValues.slice(
        weeklyUserCount.actualValues.length - 96,
        weeklyUserCount.actualValues.length - 48
      );
      const dubWeeklyUserCount = { ...weeklyUserCount };
      dubWeeklyUserCount.calculatedValue =
        secondLastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0) -
        LastNumber.reduce(function cal(a, b) {
          return a + b;
        }, 0);
      setWeeklyUserCount(dubWeeklyUserCount);
    }
  };
  return (
    <Card
      className="sales-card"
      title={title}
      extra={
        title === 'Sales' && (
          <Select
            defaultValue="weekly"
            // showSearch
            onChange={handelChange}
            // onSearch={onSearch}
            // filterOption={(input, option) =>
            //   option.children
            //     .toLowerCase()
            //     .indexOf(input.toLowerCase()) >= 0
            // }
          >
            <Select.Option value="weekly">weekly</Select.Option>
            <Select.Option value="monthly">monthly</Select.Option>
            <Select.Option value="quarterly">quarterly</Select.Option>
            <Select.Option value="biannual">biannual</Select.Option>
            <Select.Option value="yearly">yearly</Select.Option>
          </Select>
        )
      }
    >
      <div className={`${prefix ? 'd-flex' : ''}`}>
        {prefix ? <div className="mr-2">{prefix}</div> : null}
        <div>
          <div className="d-flex align-items-center">
            <h1 className="mb-0 font-weight-bold">{value}</h1>
            <span
              className={`font-size-md font-weight-bold ml-3 ${
                status > 0 ? 'text-success' : 'text-danger'
              }`}
            >
              {status || 0.0}{' '}
              {title === 'Sales' || title === 'Revenue' ? '%' : ''}
              {status > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            </span>
          </div>
          <div className="text-gray-light mt-1">{subtitle || ' '}</div>
        </div>
      </div>
    </Card>
  );
};

export default StatisticWidget;
