import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Col,
  Card,
  Input,
  Select,
  InputNumber,
  message,
} from 'antd';
import { v1 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import Flex from '../../../sharedComponents/flex';
import Content from '../settingsInnerContent/content';
import { editCollections, currency } from '../../../utils/function';
import './style.scss';

const ADD = 'ADD';
const EDIT = 'EDIT';
const CheckoutForm = (props) => {
  const { TextArea } = Input;
  const { mode: newMode, param, history } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(newMode || ADD);
  const [id, setId] = useState(param ? param.id : null);
  const [selectedClub, setSelectedClub] = useState(param ? param.id : null);
  const club = useSelector((state) => state.club.selectedClub);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setSelectedClub(club);
    if (mode === 'EDIT') {
      const checkout =
        selectedClub &&
        selectedClub.checkout &&
        selectedClub.checkout.find((sin) => sin.id === id);
      if (checkout) {
        form.setFieldsValue({
          id: checkout.id,
          address2: checkout.address2,
          billingFrequency: checkout.billingFrequency,
          description: checkout.description,
          itemCount: checkout.itemCount,
          price: currency(checkout.price),
          shippingCost: currency(checkout.shippingCost),
          title: checkout.title,
        });
      }
    }
  }, [form, mode, param, selectedClub, club]);
  const rules = {
    price: [
      {
        required: true,
        message: 'Please enter Price',
      },
    ],
    title: [
      {
        required: true,
        message: 'Please enter Title',
      },
    ],
    description: [
      {
        required: true,
        message: 'Please enter Description',
      },
    ],
    billingFrequency: [
      {
        required: true,
        message: 'Please Select Billing Frequency',
      },
    ],
    shippingCost: [
      {
        required: true,
        message: 'Please enter Shipping Cost',
      },
    ],
    itemCount: [
      {
        required: true,
        message: 'Please enter Item Count',
      },
      {
        type: 'number',
        message: 'Please enter valid Item Count',
      },
    ],
  };

  const onSuccess = () => {
    setLoading(false);
    if (mode === ADD) {
      message.success(`Plan successfully added`);
      history.push('/settings/checkout');
      setMode(EDIT);
    } else if (mode === EDIT) {
      message.success(`Plan successfully updated`);
      history.push('/settings/checkout');
    }
  };

  const onError = () => {
    setLoading(false);
    message.error('Something went wrong please try again');
  };

  const onFinish = () => {
    form.validateFields().then((data) => {
      const values = data;
      if (mode === 'EDIT') {
        setLoading(true);
        values.id = values.id ? values.id : id;
        values.price = parseFloat(values.price.substr(1));
        values.shippingCost = values.shippingCost.substr(1);
        setId(values.id);
        const dublicateClub = { ...selectedClub };
        dublicateClub.checkout = dublicateClub.checkout.map((each) => {
          const info = { ...each };
          if (info.id === values.id) {
            return values;
          }
          return info;
        });
        editCollections('clubs', dublicateClub, onSuccess, onError);
      } else {
        setLoading(true);
        const dublicateClub = { ...selectedClub };
        dublicateClub.checkout = Object.assign([], dublicateClub.checkout);
        values.id = uuid();
        values.price = values.price.substr(1);
        values.shippingCost = values.shippingCost.substr(1);
        setId(values.id);
        dublicateClub.checkout.push(values);
        editCollections('clubs', dublicateClub, onSuccess, onError);
      }
    });
    // .catch((err) => {
    //   setLoading(false);
    //   message.error('Please enter all required field ');
    // });
  };

  const onChange = () => {};
  const onSearch = () => {};

  return (
    <div className="w-100 d-flex ">
      <div className="w-30 px-4">
        <Content
          heading="Club Plans"
          content="Manage the different plans that your customers can subscribe."
        />
      </div>
      <div className="settings-checkout-form w-70">
        <Form layout="vertical" form={form} name="" className="">
          <Col xs={24} sm={24} md={13} lg={24} xlg={24}>
            <Card>
              <Flex
                className="py-2 font-weight-bold"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">
                  {mode === 'ADD' ? 'Add Plan' : `Edit Plan`}{' '}
                </h2>
                <div className="mb-3">
                  {mode === 'ADD' && (
                    <Button
                      className="mr-2"
                      onClick={() => {
                        history.push('/settings/checkout');
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={() => onFinish()}
                    loading={loading}
                  >
                    {mode === 'ADD' ? 'Add' : `Save`}
                  </Button>
                </div>
              </Flex>
              <Form.Item name="price" label="Price" rules={rules.price}>
                <InputNumber
                  placeholder="Price"
                  onChange={(value) => {
                    form.setFieldsValue({
                      price: currency(value),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item name="title" label="Title" rules={rules.title}>
                <Input placeholder="Title" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={rules.description}
              >
                <TextArea placeholder="Description" />
              </Form.Item>
              <Form.Item
                name="billingFrequency"
                label="Billing Frequency"
                rules={rules.billingFrequency}
              >
                <Select
                  showSearch
                  placeholder="Select Billing Frequency"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <option value="weekly">weekly</option>
                  <option value="monthly">monthly</option>
                  <option value="quarterly">quarterly</option>
                  <option value="biannual">biannual</option>
                  <option value="yearly">yearly</option>
                </Select>
              </Form.Item>
              <Form.Item
                name="itemCount"
                label="Item Count"
                rules={rules.itemCount}
              >
                <InputNumber placeholder="Item Count" />
              </Form.Item>
              <Form.Item
                name="shippingCost"
                label="Shipping Cost"
                rules={rules.shippingCost}
              >
                <InputNumber
                  placeholder="Shipping Cost"
                  min={0}
                  onChange={(value) => {
                    form.setFieldsValue({
                      shippingCost: currency(value),
                    });
                  }}
                />
              </Form.Item>
              {/* <div className="pt-4">
                <Button type="primary" htmlType="submit">
                  Edit
                </Button>
              </div> */}
            </Card>{' '}
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(CheckoutForm);
